import request from "@/utils/request";

// 增加主题
export function addExamTheme(data) {
  return request({
      url: '/admin/french/addExamTheme',
      method: 'post',
      data
  })
}

// 添加题目
export function addExamTitle(data) {
  return request({
      url: '/admin/french/addExamTitle',
      method: 'post',
      data
  })
}

//主题列表
export function themeListByType(params) {
  return request({
      url: '/admin/french/themeListByType',
      method: 'get',
      params
  })
}

//题目列表
export function titleListByThemeId(params) {
  return request({
      url: '/admin/french/titleListByThemeId',
      method: 'get',
      params
  })
}

// 修改题目
export function updateTitleById(data) {
  return request({
      url: '/admin/french/updateTitleById',
      method: 'post',
      data
  })
}

// 删除题目
export function delTitleById(data) {
  return request({
      url: '/admin/french/delTitleById',
      method: 'delete',
      data
  })
}

// 获取上传COS签名
export function examCosToken(params) {
  return request({
      url: '/admin/french/examCosToken',
      method: 'get',
      params
  })
}

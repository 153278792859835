import request from "@/utils/request";

export function getMaterial(data) {
  return request({
    url: "/admin/base/fileCateList",
    method: "get",
    params: data
  });
}
export function addCategory(data) {
  return request({
    url: "/admin/base/fileCateAdd",
    method: "post",
    data
  });
}
export function removeCategory(data) {
  return request({
    url: "/admin/base/fileCateDel",
    method: "delete",
    data: {
      id:data
    }
  });
}
export function editCategory(data) {
  return request({
    url: "/admin/base/fileCateEdit",
    method: "put",
    data
  });
}

export function selectMaterial(data) {
  return request({
    url: "/admin/base/selectFile",
    method: "get",
    params: data
  });
}

export function getFile(data) {
  return request({
    url: "/admin/base/fileList",
    method: "get",
    params: data
  });
}
export function editFile(data) {
  return request({
    url: "/admin/base/fileEdit",
    method: "put",
    data
  });
}
export function delFile(data) {
  return request({
    url: "/admin/base/fileDel",
    method: "delete",
    data: {
      id:data
    }
  });
}
export function addFile(data) {
  return request({
    url: "/admin/base/fileAdd",
    method: "post",
    data
  });
}

export function FileRecycleDel(data) {
  return request({
    url: "/admin/base/fileRecycleDel",
    method: "delete",
    data: {
      id:data
    }
  });
}

export function FileRestore(data) {
  return request({
    url: "/admin/base/fileRestore",
    method: "put",
    data: {
      id:data
    }
  });
}

// 申请图片素材上传token
export function FileCosToken(params) {
  return request({
    url: "/admin/base/fileCosToken",
    method: "get",
    params
  });
}

// 添加互动课题目
export function addExamTitle(data) {
  return request({
    url: "/admin/french/addExamTitle ",
    method: "post",
    data
  });
}

// 互动课题目列表
export function titleListByThemeId(params) {
  return request({
    url: "/admin/french/titleListByThemeId",
    method: "get",
    params
  });
}

// 编辑互动课题目
export function updateTitleById(data) {
  return request({
    url: "/admin/french/updateTitleById ",
    method: "post",
    data
  });
}

// 删除互动课题目
export function delTitleById(data) {
  return request({
      url: '/admin/french/delTitleById',
      method: 'delete',
      data
  })
}
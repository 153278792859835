<template>
  <div>
    <!-- 互动课题库弹窗 -->
    <el-dialog
      :title="questionBankTitle"
      :visible.sync="questionBankDialog"
      width="65%"
      @close="closeQuestionBankDialog"
    >
      <el-form
        :model="editMaterialForm"
        :rules="rules"
        ref="editMaterialForm"
        label-width="100px"
      >
        <div
          class="title_box"
          style="display:flex;justify-content:space-between;margin-bottom:10px"
        >
          <div></div>
          <div class="hearingLibDialog_title">
            <el-button type="primary" @click="addQuestionButton"
              >添加题目</el-button
            >
          </div>
        </div>
        <div style="overflow:auto;height:600px">
          <el-table
            :data="questionList"
            class="table"
            ref="userTable.multipleSelection"
            header-cell-class-name="table-header"
            :highlight-current-row="true"
            border
          >
            <el-table-column
              prop="id"
              label="ID"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              label="开始时间"
              prop="start_time"
              align="center"
              v-if="type == 1"
            >
            </el-table-column>
            <el-table-column
              label="类型"
              prop="title_info.title_class"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.title_info.title_class == 'select'">
                  单选题
                </div>
                <div v-if="scope.row.title_info.title_class == 'answer'">
                  填空题
                </div>
                <div v-if="scope.row.title_info.title_class == 'mul_select'">
                  多选题
                </div>
                <div v-if="scope.row.title_info.title_class == 'audio_select'">
                  音频选择题（可多选）
                </div>
                <div v-if="scope.row.title_info.title_class == 'audio_answer'">
                  音频填空题
                </div>
                <div v-if="scope.row.title_info.title_class == 'speak'">
                  口语练习题
                </div>
                <div v-if="scope.row.title_info.title_class == 'write'">
                  写作题
                </div>
                <div v-if="scope.row.title_info.title_class == 'pic'">
                  图片题
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="问题"
              prop="title_info.title"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="150">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  @click="editQuestion(scope.row)"
                  size="small"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="removeQuestion(scope.row)"
                  size="small"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </el-dialog>

    <!-- 互动课题库添加/编辑题目弹窗 -->
    <el-dialog
      :title="questionDialogTitle"
      :visible.sync="questionDialog"
      width="40%"
      @close="closeQuestionDialog"
      :before-close="handleClose"
    >
      <div>
        <el-tooltip class="item" effect="dark" placement="top-start">
          <div slot="content" style="width:600px;height:100px">
            <div style="font-size:16px">提示</div>
            <div style="font-size:14px;padding:4px 0px 0px 0px;">
              1.展示时间:题目在视频出现的时间。
            </div>
            <div style="font-size:14px;padding:4px 0px 0px 0px;">
              2.是否解析:开启解析则学员在答题后，可以选择是否跳转解析视频。关闭解析则学员在答题后，无解析视频选择，直接进入正课视频。
            </div>
            <div style="font-size:14px;padding:4px 0px 0px 0px;">
              3.解析视频:上传解析视频后，无论是否开启解析，在用户的错题集中都会有解析视频。
            </div>
          </div>
          <i
            class="el-icon-question"
            style="position:absolute;top:22px;left:100px;color:#37a58c;font-size:20px"
          ></i>
        </el-tooltip>
        <el-form
          :model="questionForm"
          :rules="rules"
          ref="questionForm"
          label-width="70px"
        >
          <el-form-item el-form-item label="题目类型" label-width="70px">
            <el-select
              v-model="questionForm.title_class"
              placeholder="请选择"
              clearable
            >
              <el-option
                :label="item.type"
                :value="item.id"
                v-for="item in questionTypeArr"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            el-form-item
            label="展示时间"
            label-width="70px"
            v-if="type == 1"
          >
            <el-time-picker
              v-model="questionForm.time"
              placeholder="任意时间点"
              value-format="HH:mm:ss"
              :picker-options="{
                selectableRange: '00:00:00 - 04:59:59'
              }"
              :default-value="new Date(0, 0, 0, 0, 0, 0)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item
            el-form-item
            label="题目"
            label-width="70px"
            v-if="
              questionForm.title_class == 'select' ||
                questionForm.title_class == 'mul_select' ||
                questionForm.title_class == 'answer' ||
                questionForm.title_class == 'speak' ||
                questionForm.title_class == 'write'
            "
          >
            <el-input
              v-model="questionForm.title"
              placeholder="请输入题目,题目最长400个字符"
              maxlength="400"
              show-word-limit
              type="textarea"
              :rows="3"
            />
          </el-form-item>
          <el-form-item
            el-form-item
            label="题目"
            label-width="70px"
            v-if="questionForm.title_class == 'pic'"
          >
            <el-input
              v-model="questionForm.title_desc"
              placeholder="请输入题目,题目最长400个字符"
              maxlength="400"
              show-word-limit
              type="textarea"
              :rows="3"
            />
          </el-form-item>
          <el-form-item
            el-form-item
            label="音频题目"
            label-width="70px"
            v-if="
              questionForm.title_class == 'audio_select' ||
                questionForm.title_class == 'audio_answer'
            "
          >
            <div v-if="audio_url">
              <audio
                :src="audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls"
                ref="audioPlay"
              ></audio>
            </div>
            <el-button type="primary" @click="selectFiles">{{
              isAdd == "add" ? "上传音频" : "修改音频"
            }}</el-button>
            <span class="file-name">{{ fileAudioName }}</span>
            <el-progress
              :percentage="audioProgress"
              v-if="audioProgress"
              style="width:400px;"
            ></el-progress>
            <input
              type="file"
              hidden
              ref="files"
              @change="changeFiles"
              accept="audio/*"
            />
          </el-form-item>
          <el-form-item
            el-form-item
            label="图片题目"
            label-width="70px"
            v-if="questionForm.title_class == 'pic'"
          >
            <el-upload
              action="#"
              list-type="picture-card"
              :limit="6"
              :file-list="fileList"
              :on-change="handleChange"
              :http-request="beforeKatakanaUpload"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <!-- <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span> -->
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            el-form-item
            label="选项"
            label-width="70px"
            v-if="
              questionForm.title_class == 'select' ||
                questionForm.title_class == 'audio_select' ||
                questionForm.title_class == 'mul_select'
            "
          >
            <el-input
              placeholder="请输入选项,换行为一个新选项,选项最长400个字符"
              v-model="questionForm.options"
              maxlength="400"
              show-word-limit
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            el-form-item
            label="答案"
            label-width="70px"
            v-if="
              questionForm.title_class == 'select' ||
                questionForm.title_class == 'audio_select' ||
                questionForm.title_class == 'mul_select'
            "
          >
            <div class="addtopic">
              <div></div>
              <el-button type="primary" @click="addTopicOptions()"
                >添加答案选项</el-button
              >
            </div>
            <div class="add_box">
              <div v-for="(item, index) in ABCDE" :key="index">
                <div
                  :class="selectIndex == index ? 'add_item_click' : 'add_item'"
                  @click="changeSelect(index, questionForm.title_class)"
                  v-if="questionForm.title_class == 'select'"
                >
                  <span>{{ ABCD[index] }}</span>
                </div>
                <div
                  :class="item.isSelect ? 'add_item_click' : 'add_item'"
                  @click="changeSelect(index, questionForm.title_class)"
                  v-else-if="
                    questionForm.title_class == 'mul_select' ||
                      questionForm.title_class == 'audio_select'
                  "
                >
                  <span>{{ ABCD[index] }}</span>
                </div>
                <div class="add_btn">
                  <el-button
                    v-if="
                      ABCD[index] == 'D' ||
                        ABCD[index] == 'C' ||
                        ABCD[index] == 'E'
                    "
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="removeOptions(index)"
                    size="small"
                  ></el-button>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            el-form-item
            label="答案"
            label-width="70px"
            v-if="
              questionForm.title_class == 'answer' ||
                questionForm.title_class == 'audio_answer'
            "
          >
            <el-input
              placeholder="请输入答案,答案最长200个字符"
              v-model="questionForm.right"
              maxlength="200"
              show-word-limit
              type="textarea"
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="70px" label="是否解析" v-if="type == 1">
            <el-switch
              v-model="questionForm.is_analysis"
              active-color="#3bb08d"
              inactive-color="#999999"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            el-form-item
            label="解析视频"
            label-width="70px"
            v-if="type == 1"
          >
            <div v-if="video_url">
              <video
                :src="video_url"
                style="width: 300px; height: 200px; cursor: pointer"
                autoplay
                v-if="video_url"
                @click="selectVideo"
                ref="videoPlay"
                controls
              ></video>
            </div>
            <el-button type="primary" @click="selectVideo"
              >选择视频文件</el-button
            ><span class="file-name">{{ fileVideoName }}</span>
            <el-progress
              :percentage="videoProgress"
              v-if="videoProgress"
              style="width:400px;"
            ></el-progress>
            <input
              type="file"
              name="video"
              id="aaa"
              hidden
              ref="video"
              @change="selectVideoFiles"
              accept="video/*"
            />
          </el-form-item>
          <el-form-item label-width="44%">
            <el-button
              type="primary"
              @click="addQuestionNow"
              v-if="questionDialogTitle == '添加题目'"
            >
              立即添加
            </el-button>
            <el-button
              type="primary"
              @click="editQuestionNow"
              v-if="questionDialogTitle == '编辑题目'"
            >
              立即修改
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {} from "@/api/material";
import { examCosToken } from "@/api/French/questionbank_management.js";
var COS = require("cos-js-sdk-v5");
import { log } from "util";
import DPlayer from "dplayer";
import {
  FileCosToken,
  addExamTitle,
  titleListByThemeId,
  updateTitleById,
  delTitleById
} from "@/api/material";
export default {
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: "分类名称不能为空",
            trigger: "blur"
          },
          { min: 1, max: 8, message: "长度在 1 到 8 个字符", trigger: "blur" }
        ],
        title: [
          {
            required: true,
            message: "修改的分类名称不能为空",
            trigger: "blur"
          },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur"
          }
        ],
        orderby: [
          {
            required: true,
            message: "素材排序不能为空",
            trigger: "blur"
          }
        ],
        file_name: [
          {
            required: true,
            message: "素材名称不能为空",
            trigger: "blur"
          },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur"
          }
        ],
        cate_id: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur"
          }
        ]
      },
      editMaterialForm: {
        file_name: "",
        id: "",
        cate_id: "",
        orderby: "",
        relation: [],
        mold: "",
        is_interaction: ""
      },
      questionBankDialog: false,
      questionBankTitle: "",
      questionDialog: false,
      questionTypeArr: [
        { id: "select", type: "单选题" },
        { id: "mul_select", type: "多选题" },
        { id: "answer", type: "填空题" },
        { id: "audio_select", type: "音频选择题(可多选)" },
        { id: "audio_answer", type: "音频填空题" }
      ],
      questionForm: {
        file_id: null,
        title: "",
        right: "",
        options: "",
        title_class: "",
        is_analysis: true,
        time: null,
        analysis_url: "",
        theme_id: 105,
        theme_type: 6,
        is_interaction: null,
        is_homework: null,
        title_type: "",
        options_type: "word",
        ans_type: "",
        title_duration: "",
        title_desc: ""
      },
      ABCD: ["A", "B", "C", "D", "E"],
      ABCDE: [
        { which: "A", isSelect: false },
        { which: "B", isSelect: false },
        { which: "C", isSelect: false },
        { which: "D", isSelect: false },
        { which: "E", isSelect: false }
      ],
      selectIndex: -1,
      audio_url: "",
      fileAudioName: "",
      isAdd: "",
      audioProgress: "",
      questionList: [],
      video_url: "",
      fileVideoName: "",
      videoProgress: null,
      questionDialogTitle: "",
      type: null,
      filePicName: "",
      pic_url: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      questionPicArr: []
    };
  },
  watch: {
    "questionForm.title_class": function(news, old) {
      if (news == "select") {
        this.ABCDE = [
          { which: "A", isSelect: false },
          { which: "B", isSelect: false },
          { which: "C", isSelect: false },
          { which: "D", isSelect: false }
        ];
      }
      if (old != "") {
        this.questionForm.right = "";
      }
    }
  },
  methods: {
    // 获取互动课题目列表
    getQuestionList(id, num) {
      this.questionForm.file_id = id;
      localStorage.setItem("qfi", id);
      titleListByThemeId({ file_id: id, type: num }).then(res => {
        res.data.forEach((item, index) => {
          var t = "";
          if (item.start_time > -1) {
            var hour = Math.floor(item.start_time / 3600);
            var min = Math.floor(item.start_time / 60) % 60;
            var sec = item.start_time % 60;
            if (hour < 10) {
              t = "0" + hour + ":";
            } else {
              t = hour + ":";
            }
            if (min < 10) {
              t += "0";
            }
            t += min + ":";
            if (sec < 10) {
              t += "0";
            }
            t += sec.toFixed(0);
          }
          item.start_time = t;
          if (
            item.title_info.title_class == "mul_select" &&
            item.title_info.title_type == "audio"
          ) {
            item.title_info.title_class = "audio_select";
          } else if (
            item.title_info.title_class == "answer" &&
            item.title_info.title_type == "audio"
          ) {
            item.title_info.title_class = "audio_answer";
          } else if (
            item.title_info.title_class == "answer" &&
            item.title_info.title_type == "word" &&
            item.title_info.ans_type == "audio"
          ) {
            item.title_info.title_class = "speak";
          } else if (
            item.title_info.title_class == "answer" &&
            item.title_info.title_type == "word" &&
            item.title_info.ans_type == "text"
          ) {
            item.title_info.title_class = "write";
          } else if (
            item.title_info.title_class == "answer" &&
            item.title_info.title_type == "pic" &&
            item.title_info.ans_type == "pic"
          ) {
            item.title_info.title_class = "pic";
          }
        });
        // console.log(res.data)
        this.questionList = res.data;
      });
    },
    // 点击添加题目
    addQuestionButton() {
      if (this.questionList.length == 40) {
        this.$message.error("已达到题目数量上限40个,不能再添加");
        return;
      }
      if (this.type == 2) {
        this.questionTypeArr.push(
          // { id: "speak", type: "练习口语题" },
          { id: "write", type: "写作题" },
          { id: "pic", type: "其他题" }
        );
      }
      this.questionDialog = true;
      this.questionDialogTitle = "添加题目";
      this.isAdd = "add";
    },
    // 点击选择音频按钮
    selectFiles() {
      this.$refs.files.click();
    },
    // 选中音频文件
    changeFiles(e) {
      const files = e.target.files[0];
      // 定义一个音频实例对象
      let audio = new Audio();
      // 将本地的音频地址给这个空的音频实例对象，从而可以得到我们需要的数据。
      try {
        audio.srcObject = files;
      } catch {
        audio.src = URL.createObjectURL(files);
      }
      // 当音频的元数据加载完成时就可以获取到音频、视频的时长、尺寸、以及文本轨道等内容。
      audio.addEventListener("loadedmetadata", () => {
        // 这是视频音频的播放时长
        // console.log(Math.floor(audio.duration)); // 得到这个音频的播放时长，单位是秒。
        this.questionForm.title_duration = Math.floor(audio.duration);
      });

      let file = this.$refs.files.files[0];
      this.fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      examCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.audioProgress = progressData.percent * 100;
            }
          },
          function(err, data) {
            if (data) {
              that.questionForm.title = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList; //把文件给data里的fileList
      console.log(this.fileList);
    },
    handleRemove(file) {
      let delIndex = this.fileList.indexOf(file);
      if (delIndex > -1) {
        this.fileList.splice(delIndex, 1);
        this.questionPicArr.splice(delIndex, 1);
      }
      console.log(this.fileList, this.questionPicArr);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    // 上传图片
    beforeKatakanaUpload(file) {
      // const isSize = file.size / 1024 / 1024 < 1;
      // if (!isSize) {
      //   this.$message.error("上传缩略图不能超过 1MB!");
      //   return;
      // }
      let that = this;
      that.filePicName = file.file.name;
      examCosToken({ suffix: file.file.name }).then(res => {
        this.questionPicArr.push(res.data.key);
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file.file, // 上传文件对象
            onProgress: function(progressData) {}
          },
          function(err, data) {
            if (data) {
              const reader = new FileReader();
              reader.readAsDataURL(file.file);
              reader.onload = e => {
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },
    // 点击选择视频文件按钮
    selectVideo() {
      this.$refs.video.click();
    },

    // 选择了视频文件
    selectVideoFiles() {
      let file = this.$refs.video.files[0];
      if (this.$refs.video.files.length == 0) {
        this.questionForm.analysis_url = "";
        return;
      }
      this.fileVideoName = file.name;
      // console.log(this.fileVideoName)
      // if (file.size / 1024 / 1024 > 30) {
      //   this.$message.error("音频不能大于 30M ");
      //   return;
      // }
      FileCosToken({ suffix: file.name }).then(res => {
        this.CosObj = res.data;
        var that = this;
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: that.CosObj.Bucket,
            Region: that.CosObj.Region,
            Key: that.CosObj.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.videoProgress = progressData.percent * 100;
              that.questionForm.analysis_url = that.CosObj.key;
              that.video_url = "";
            },
            onTaskReady: taskId => {
              console.log(taskId);
              // that.taskId = taskId;
            }
          },
          function(err, data) {
            console.log(err || data);
          }
        );
      });
    },
    // 添加选项
    addTopicOptions() {
      if (this.ABCDE.length >= 4 && this.questionForm.title_class == "select") {
        this.$message.error("选项不能多于4项");
        return;
      } else if (
        (this.ABCDE.length >= 5 &&
          this.questionForm.title_class == "mul_select") ||
        (this.ABCDE.length >= 5 &&
          this.questionForm.title_class == "audio_select")
      ) {
        this.$message.error("选项不能多于5项");
        return;
      }
      this.ABCDE.push({ which: "", isSelect: false });
    },

    // 删除选项
    removeOptions(index) {
      if (this.ABCDE.length <= 2) {
        this.$message.error("选项不少于2项");
        return;
      }
      this.ABCDE.splice(index, 1);
      if (this.questionForm.title_class == "select") {
        if (index == 2 || 3 || 4) {
          this.questionForm.right = "";
          this.selectIndex = -1;
        }
      } else if (
        this.questionForm.title_class == "mul_select" ||
        this.questionForm.title_class == "audio_select"
      ) {
        if (index == 2 || 3 || 4) {
          this.questionForm.right = "";
          this.ABCDE.forEach(item => {
            item.isSelect = false;
          });
        }
      }
    },
    // 选择题选择答案
    changeSelect(index, type) {
      if (type == "select") {
        this.selectIndex = index;
        this.questionForm.right = this.ABCD[index];
      } else if (type == "mul_select" || type == "audio_select") {
        this.ABCDE[index].isSelect = !this.ABCDE[index].isSelect;
        this.questionForm.right = "";
        this.ABCDE.forEach((item, index) => {
          if (item.isSelect) {
            if (this.questionForm.right == "") {
              this.questionForm.right = this.ABCD[index];
            } else {
              this.questionForm.right =
                this.questionForm.right + "," + this.ABCD[index];
            }
          }
        });
      }
    },
    // 点击立即添加
    addQuestionNow() {
      // console.log(this.questionForm.file_id)
      console.log(
        this.questionForm.right.length,
        this.questionForm.title_class
      );
      if (this.questionForm.title_class == "pic") {
        this.questionForm.title = this.questionPicArr.toString();
      }
      console.log(this.questionForm.title);
      if (this.questionForm.file_id == null) {
        this.questionForm.file_id = localStorage.getItem("qfi");
      }
      if (this.questionForm.title_class == "") {
        this.$message.error("请选择题目类型");
        return;
      } else if (this.questionForm.time == null && this.type == 1) {
        this.$message.error("请选择展示时间");
        return;
      } else if (
        this.questionForm.title == "" &&
        this.questionForm.title_class != "pic"
      ) {
        this.$message.error("请输入题目");
        return;
      } else if (
        this.questionForm.title_desc == "" &&
        this.questionForm.title_class == "pic"
      ) {
        this.$message.error("请输入题目");
        return;
      } else if (
        this.questionForm.title == "" &&
        this.questionForm.title_class == "pic"
      ) {
        this.$message.error("请上传图片");
        return;
      } else if (
        (this.questionForm.options == null &&
          this.questionForm.title_class != "answer") ||
        (this.questionForm.options == null &&
          this.questionForm.title_class != "audio_answer")
      ) {
        this.$message.error("请输入选项");
        return;
      } else if (
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "select") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "mul_select") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "answer") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "audio_answer") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "audio_select")
      ) {
        this.$message.error("请选择正确答案");
        return;
      } else if (
        this.questionForm.is_analysis == true &&
        this.questionForm.analysis_url == "" &&
        this.type == 1
      ) {
        this.$message.error("请上传解析视频");
        return;
      } else if (
        this.questionList.some((item, index) => {
          if (this.questionForm.time == item.start_time) {
            return true;
          }
        }) &&
        this.type == 1
      ) {
        this.$message.error("该时间段已有题目,请更换时间段");
        return;
      }
      if (this.type == 1) {
        this.questionForm.is_interaction = 1;
        this.questionForm.is_homework = 0;
      } else if (this.type == 2) {
        this.questionForm.is_interaction = 0;
        this.questionForm.is_homework = 1;
      }
      let data = { ...this.questionForm };
      if (this.questionForm.title_class == "audio_select") {
        data.title_class = "mul_select";
        data.title_type = "audio";
        data.ans_type = "word";
      } else if (this.questionForm.title_class == "audio_answer") {
        data.title_class = "answer";
        data.title_type = "audio";
        data.ans_type = "word";
      } else if (this.questionForm.title_class == "speak") {
        data.title_class = "answer";
        data.title_type = "word";
        data.ans_type = "audio";
      } else if (this.questionForm.title_class == "write") {
        data.title_class = "answer";
        data.title_type = "word";
        data.ans_type = "text";
      } else if (this.questionForm.title_class == "pic") {
        data.title_class = "answer";
        data.title_type = "pic";
        data.ans_type = "pic";
      } else {
        data.title_type = "word";
        data.ans_type = "word";
      }
      let option = {};
      data.options.split("\n").forEach((item, index) => {
        if (index == 0) {
          option.A = item;
        } else if (index == 1) {
          option.B = item;
        } else if (index == 2) {
          option.C = item;
        } else if (index == 3) {
          option.D = item;
        } else if (index == 4) {
          option.E = item;
        }
      });
      data.options = option;
      if (this.type == 1) {
        data.is_analysis = Number(data.is_analysis);
        var times = data.time.split(":");
        var second = 0;
        second += parseInt(times[0]) * 3600;
        second += parseInt(times[1]) * 60;
        second += parseInt(times[2]);
        data.start_time = second;
        delete data.time;
      } else {
        data.is_analysis = false;
      }

      console.log(data);

      addExamTitle(data).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.questionDialog = false;
        this.getQuestionList(this.questionForm.file_id, this.type);
      });
    },
    // 点击题目列表编辑按钮
    editQuestion(row) {
      console.log(row);
      this.isAdd = "edit";
      if (this.type == 1) {
        this.questionForm.is_interaction = 1;
        this.questionForm.is_homework = 0;
      } else if (this.type == 2) {
        this.questionForm.is_interaction = 0;
        this.questionForm.is_homework = 1;
        this.questionTypeArr.push(
          // { id: "speak", type: "练习口语题" },
          { id: "write", type: "写作题" },
          { id: "pic", type: "其他题" }
        );
      }
      this.questionForm.theme_id = 105;
      this.questionForm.theme_type = 6;
      this.questionForm.options_type = "word";
      this.questionForm.file_id = row.file_id;
      this.questionForm.time = row.start_time;
      if (row.is_analysis == 1) {
        this.questionForm.is_analysis = true;
      } else if (row.is_analysis == 0) {
        this.questionForm.is_analysis = false;
      }
      this.questionForm.analysis_url = row.analysis_url;
      this.questionForm.interaction_id = row.id;
      this.questionForm.id = row.exam_title_id;
      this.questionForm.title = row.title_info.title;
      this.questionForm.right = row.title_info.right;
      this.questionForm.title_desc = row.title_info.title_desc;
      if (row.title_info.titles.length > 0) {
        this.fileList = row.title_info.titles.map(item => {
          return {
            name: item,
            url: item
          };
        });
      } else {
        this.fileList = [
          { name: row.title_info.http_title, url: row.title_info.http_title }
        ];
      }
      this.questionPicArr = row.title_info.title.split(",");
      console.log(this.fileList, this.questionPicArr);
      if (
        row.title_info.title_class == "audio_select" &&
        row.title_info.title_type == "audio"
      ) {
        this.questionForm.title_class = "audio_select";
        this.audio_url = row.title_info.http_title;
      } else if (
        row.title_info.title_class == "audio_answer" &&
        row.title_info.title_type == "audio"
      ) {
        this.questionForm.title_class = "audio_answer";
        this.audio_url = row.title_info.http_title;
      } else if (
        row.title_info.title_class == "pic" &&
        row.title_info.title_type == "pic"
      ) {
        this.questionForm.title_class = "pic";
        this.pic_url = row.title_info.http_title;
      } else {
        this.questionForm.title_class = row.title_info.title_class;
      }
      if (this.questionForm.title_class == "select") {
        this.selectIndex = this.ABCD.indexOf(row.title_info.right);
      } else if (
        this.questionForm.title_class == "mul_select" ||
        this.questionForm.title_class == "audio_select"
      ) {
        row.title_info.right.split(",").forEach(item => {
          this.ABCDE[this.ABCD.indexOf(item)].isSelect = true;
          console.log(this.ABCDE);
        });
      }
      let optionsArr = [];
      let obj = JSON.parse(row.title_info.options.replace(/[\\]/g, ""));
      for (var item in obj) {
        if (item == "A") {
          optionsArr[0] = unescape(obj[item].replace(/u/g, "%u"));
          optionsArr[0] = unescape(optionsArr[0].replace(/%/g, ""));
          this.questionForm.options = optionsArr[0];
        } else if (item == "B") {
          optionsArr[1] = unescape(obj[item].replace(/u/g, "%u"));
          optionsArr[1] = unescape(optionsArr[1].replace(/%/g, ""));
          this.questionForm.options =
            this.questionForm.options + "\n" + optionsArr[1];
        } else if (item == "C") {
          optionsArr[2] = unescape(obj[item].replace(/u/g, "%u"));
          optionsArr[2] = unescape(optionsArr[2].replace(/%/g, ""));
          this.questionForm.options =
            this.questionForm.options + "\n" + optionsArr[2];
        } else if (item == "D") {
          optionsArr[3] = unescape(obj[item].replace(/u/g, "%u"));
          optionsArr[3] = unescape(optionsArr[3].replace(/%/g, ""));
          this.questionForm.options =
            this.questionForm.options + "\n" + optionsArr[3];
        } else if (item == "E") {
          optionsArr[4] = unescape(obj[item].replace(/u/g, "%u"));
          optionsArr[4] = unescape(optionsArr[4].replace(/%/g, ""));
          this.questionForm.options =
            this.questionForm.options + "\n" + optionsArr[4];
        }
      }
      // console.log(this.questionForm.options)
      this.video_url = row.http_analysis_url;
      this.questionDialog = true;
      this.questionDialogTitle = "编辑题目";
    },
    // 点击立即修改
    editQuestionNow() {
      if (this.questionForm.title_class == "pic") {
        this.questionForm.title = this.questionPicArr.toString();
      }
      if (this.questionForm.title_class == "") {
        this.$message.error("请选择题目类型");
        return;
      } else if (this.questionForm.time == null && this.type == 1) {
        this.$message.error("请选择展示时间");
        return;
      } else if (
        this.questionForm.title == "" &&
        this.questionForm.title_class != "pic"
      ) {
        this.$message.error("请输入题目");
        return;
      } else if (
        this.questionForm.title_desc == "" &&
        this.questionForm.title_class == "pic"
      ) {
        this.$message.error("请输入题目");
        return;
      } else if (
        this.questionForm.title == "" &&
        this.questionForm.title_class == "pic"
      ) {
        this.$message.error("请上传图片");
        return;
      } else if (
        (this.questionForm.options == null &&
          this.questionForm.title_class != "answer") ||
        (this.questionForm.options == null &&
          this.questionForm.title_class != "audio_answer")
      ) {
        this.$message.error("请输入选项");
        return;
      } else if (
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "select") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "mul_select") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "answer") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "audio_answer") ||
        (this.questionForm.right.length == "" &&
          this.questionForm.title_class == "audio_select")
      ) {
        this.$message.error("请选择正确答案");
        return;
      } else if (
        this.questionForm.is_analysis == true &&
        this.questionForm.analysis_url == "" &&
        this.type == 1
      ) {
        this.$message.error("请上传解析视频");
        return;
      } else if (
        this.questionList.some((item, index) => {
          if (
            this.questionForm.time == item.start_time &&
            this.questionForm.interaction_id != item.id
          ) {
            return true;
          }
        }) &&
        this.type == 1
      ) {
        this.$message.error("该时间段已有题目,请更换时间段");
        return;
      }

      let data = { ...this.questionForm };
      if (this.questionForm.title_class == "audio_select") {
        data.title_class = "mul_select";
        data.title_type = "audio";
      } else if (this.questionForm.title_class == "audio_answer") {
        data.title_class = "answer";
        data.title_type = "audio";
      } else if (this.questionForm.title_class == "speak") {
        data.title_class = "answer";
        data.title_type = "word";
        data.ans_type = "audio";
      } else if (this.questionForm.title_class == "write") {
        data.title_class = "answer";
        data.title_type = "word";
        data.ans_type = "text";
      } else if (this.questionForm.title_class == "pic") {
        data.title_class = "answer";
        data.title_type = "pic";
        data.ans_type = "pic";
      } else {
        data.title_type = "word";
        data.ans_type = "word";
      }
      let option = {};
      data.options.split("\n").forEach((item, index) => {
        if (index == 0) {
          option.A = item;
        } else if (index == 1) {
          option.B = item;
        } else if (index == 2) {
          option.C = item;
        } else if (index == 3) {
          option.D = item;
        } else if (index == 4) {
          option.E = item;
        }
      });
      data.options = option;
      data.is_analysis = Number(data.is_analysis);
      var times = data.time.split(":");
      var second = 0;
      second += parseInt(times[0]) * 3600;
      second += parseInt(times[1]) * 60;
      second += parseInt(times[2]);
      data.start_time = second;
      delete data.time;
      console.log(data);

      updateTitleById(data).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.questionDialog = false;
        this.getQuestionList(this.questionForm.file_id, this.type);
        this.fileAudioName = "";
        this.audioProgress = "";
        this.audio_url = "";
      });
    },
    // 删除题目
    async removeQuestion(row) {
      console.log(row);
      const result = await this.$confirm(
        "删除将无法恢复，您确认要删除吗?",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      delTitleById({ id: row.exam_title_id, interaction_id: row.id }).then(
        res => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("删除题库成功");
          this.getQuestionList(row.file_id, this.type);
        }
      );
    },
    // 关闭题目列表弹窗
    closeQuestionBankDialog() {
      this.questionTypeArr = [
        { id: "select", type: "单选题" },
        { id: "mul_select", type: "多选题" },
        { id: "answer", type: "填空题" },
        { id: "audio_select", type: "音频选择题(可多选)" },
        { id: "audio_answer", type: "音频填空题" }
      ];
    },
    // 关闭添加、修改问题弹窗
    closeQuestionDialog() {
      this.questionTypeArr = [
        { id: "select", type: "单选题" },
        { id: "mul_select", type: "多选题" },
        { id: "answer", type: "填空题" },
        { id: "audio_select", type: "音频选择题(可多选)" },
        { id: "audio_answer", type: "音频填空题" }
      ];
      this.questionForm = {
        file_id: null,
        title: "",
        right: "",
        options: "",
        title_class: "",
        is_analysis: true,
        time: null,
        analysis_url: "",
        theme_id: 105,
        theme_type: 6,
        is_interaction: null,
        is_homework: null,
        title_type: "",
        options_type: "word",
        title_duration: "",
        ans_type: "",
        title_desc: ""
      };
      this.video_url = "";
      this.fileVideoName = "";
      this.videoProgress = null;
      this.questionDialogTitle = "";
      // document.getElementById("aaa").value = '';
      if (this.type == 1) {
        this.$refs.video.value = "";
      }
      this.ABCD = ["A", "B", "C", "D", "E"];
      this.ABCDE = [
        { which: "A", isSelect: false },
        { which: "B", isSelect: false },
        { which: "C", isSelect: false },
        { which: "D", isSelect: false },
        { which: "E", isSelect: false }
      ];
      this.selectIndex = -1;
      this.audio_url = "";
      this.fileAudioName = "";
      this.audioProgress = "";
      this.isAdd = "";
      this.fileList = [];
      this.questionPicArr = [];
    },
    // 点击添加、编辑弹窗右上角的❌号
    handleClose(done) {
      this.$confirm("取消将无法保存您的设置，您确认退出吗？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
li {
  list-style: none;
  cursor: pointer;
}
.questionItem {
  background-color: #eee;
  border-radius: 5px;
}
.title_name {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  span {
    font-size: 16px;
    font-weight: bold;
  }
  i {
    font-size: 20px;
  }
}
.questionContent {
  padding-right: 30px;
}
.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  margin-bottom: 20px;
  display: flex;
  .add_item {
    // flex: 1;
    // display: flex;
    width: 50px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
  }
  .add_item_click {
    width: 50px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 5px;
    background-color: #37a58c;
    color: #fff;
  }
  .add_btn {
    width: 100px;
    margin-left: 10px;
  }
}
.bottomBox {
  .homeworkType {
    display: flex;
    justify-content: space-around;
    background-color: #eee;
    border-radius: 5px;
    padding: 15px;
    margin: 15px 0px;
    div {
      width: 80px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ccc;
      text-align: center;
      border-radius: 5px;
      font-size: 16px;
      background-color: #fff;
    }
    div:hover {
      background-color: #37a58c;
      color: #fff;
    }
  }
  .bottomBtn {
    display: flex;
    justify-content: space-around;
  }
}
</style>
